import React, { useState } from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import isEmptyString from 'utils/is-empty-string'
import pmt from 'utils/pmt'
import { getSrc, GatsbyImage } from 'gatsby-plugin-image'
import UnstyledButton from 'components/button/unstyled-button'
import Template from 'templates/product-template'
import { fbq, fbqEvents, gtag, gtagEvents } from '@hutson/utils'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

const packageName = 'Gardener'
const handle = 'gardener'
const title = `${packageName} 3025E Tractor Package`

const expirationDate = new Date('2024-10-25T00:00:00-06:00')
const expired = Date.now() > expirationDate
const expirationDateString = 'October 25, 2024'

const price = 31225
const financingNumberPayments = 84
const financingInterestRate = 0
const payment = Math.ceil(pmt(financingInterestRate, financingNumberPayments, price))

const includes = [
  '300E loader',
  '42-in Frontier pallet forks',
  '62-in Frontier rotary tiller',
  '6-Year/2,000 Hour Powertrain Warranty',
]
const overview = `<ul>${includes.map(str => `<li>${str}</li>`).join('')}</ul>`
const disclaimer = `${
  expired ? '' : `Offer ends ${expirationDateString}. `
}Prices and availability may vary. Taxes not included. Images for illustration purposes only. Financing subject to approved credit by John Deere Financial. Down payment may be required to receive financing. Taxes, freight, set up and delivery charges could increase the monthly payment. Restrictions may apply outside of Hutson’s area of responsibility. Terms and conditions may apply. See dealer for details.`

const GardenerTractorPackage = props => {
  const {
    data: {
      productImage,
      loaderImage,
      palletForksImage,
      tillerImage,
      deereProduct,
      deereProduct: {
        attachmentsBrochure,
        bodyHtml,
        productBrochure,
        reviews,
        sanityData: { category, categoryCode, images: sanityImages, subcategory, subcategoryCode },
        videos,
      },
      sanitySiteMetadata,
    },
    location,
  } = props
  const keyFeatures = [
    {
      title: '300E Loader',
      copy: 'Get more done with a 977 lb capacity loader. Loaders come standard with Quick Attach capabilities. Parking kits are also available to make storage a breeze.',
      image: loaderImage.childImageSharp.gatsbyImageData,
    },
    {
      title: 'Pallet forks',
      copy: 'Package includes adjustable Frontier pallet forks. Pallet forks are quick-change compatible, making it quick and easy to connect.',
      image: palletForksImage.childImageSharp.gatsbyImageData,
    },
    {
      title: 'Rotary tiller',
      copy: 'Gardening and landscaping made easy. Frontier rotary tillers have skid shoes adjustable to work up to 7 in deep. Compatible with the iMatch Quick Hitch and Quik-Knect Reciever Kit.',
      image: tillerImage.childImageSharp.gatsbyImageData,
    },
  ]

  const breadcrumbs = [
    {
      name: 'Home',
      schemaName: 'Hutson Inc',
      link: '/',
    },
    {
      name: category.title,
      link: `/${category.handle}/`,
    },
    {
      name: subcategory.title,
      link: `/${category.handle}/${subcategory.handle}/`,
    },
    {
      name: 'Hutson Tractor Packages',
      link: `/${category.handle}/${subcategory.handle}/hutson-tractor-packages/`,
    },
    {
      name: packageName,
      link: `/${category.handle}/${subcategory.handle}/hutson-tractor-packages/${handle}/`,
    },
  ]

  const meta = {
    images: [`${sanitySiteMetadata.url}${getSrc(productImage.childImageSharp.original)}`],
    title: `${title} | Hutson Inc`,
  }

  const images = [
    {
      original: productImage.childImageSharp.original,
      thumbnail: productImage.childImageSharp.thumbnail,
      originalAlt: '',
      thumbnailAlt: '',
      renderItem: _renderImg,
      renderThumbInner: _renderImgThumb,
    },
  ]

  if (Array.isArray(sanityImages) && sanityImages.length > 0) {
    sanityImages.forEach(obj => {
      images.push({
        original: obj.asset.original,
        thumbnail: obj.asset.thumbnail,
        originalAlt: '',
        thumbnailAlt: '',
        renderItem: _renderImg,
        renderThumbInner: _renderImgThumb,
      })
      meta.images.push(getSrc(obj.asset.original))
    })
  }

  const pricingLinks = []
  let secondaryButton
  if (subcategory.ocaEquipmentType) {
    const ocaLink = `https://creditapp.financial.deere.com/?dealer=034321&ref=${handle}-tractor-package&type=${subcategory.ocaEquipmentType}&country=us`
    if (secondaryButton) {
      pricingLinks.push(
        <OutboundLink
          href={ocaLink}
          key='apply-for-financing'
          id='apply-for-financing'
          onClick={trackApplyForFinancing}
        >
          Apply for financing
        </OutboundLink>
      )
    } else {
      secondaryButton = {
        as: SecondaryButtonA,
        href: ocaLink,
        id: 'apply-for-financing',
        children: 'Apply for financing',
        onClick: trackApplyForFinancing,
      }
    }
  }
  const resourceLinks = []
  if (productBrochure) {
    resourceLinks.push(
      <a
        href={productBrochure}
        target='_blank'
        rel='noopener noreferrer'
        id='request-brochure'
        key='product-brochure'
        onClick={trackProductBrochure}
      >
        View product brochure
      </a>
    )
  }
  if (attachmentsBrochure) {
    resourceLinks.push(
      <a
        href={attachmentsBrochure}
        target='_blank'
        rel='noopener noreferrer'
        id='request-attachments-brochure'
        key='attachments-brochure'
        onClick={trackProductBrochure}
      >
        View attachments &amp; implements brochure
      </a>
    )
  }

  const [showVideo, setShowVideo] = useState({})

  const toggleShowVideo = url => {
    const newState = {
      ...showVideo,
      [url]: !showVideo[url],
    }
    setShowVideo(newState)
  }

  const _renderVideo = item => {
    return showVideo[item.embedUrl] ? (
      <div className='image-gallery-image'>
        <div className='video-wrapper'>
          <iframe title='Related Video' src={item.embedUrl} frameBorder='0' allowFullScreen />
        </div>
      </div>
    ) : (
      <div>
        <PlayButton
          aria-label='Load video'
          onClick={() => toggleShowVideo(item.embedUrl)}
          role='button'
        />
        <div className='image-gallery-gatsby-image'>
          <div className='image-gallery-gatsby-image-inner'>
            <GatsbyImage
              image={item.original}
              alt={item.originalAlt}
              objectFit='contain'
              style={{ width: '100%', height: '100%' }}
            />
          </div>
        </div>
      </div>
    )
  }

  const galleryImages = [
    ...images,
    ...(Array.isArray(videos)
      ? videos.map(video => {
          return {
            original: video.thumbnail.childImageSharp.original,
            thumbnail: video.thumbnail.childImageSharp.thumbnail,
            originalAlt: '',
            thumbnailAlt: '',
            embedUrl: `https://www.youtube.com/embed/${video.id}?rel=0`,
            renderItem: _renderVideo,
            renderThumbInner: _renderImgThumb,
          }
        })
      : []),
  ]
  return (
    <Template
      breadcrumbs={breadcrumbs}
      description={isEmptyString(bodyHtml) ? null : bodyHtml}
      disclaimer={disclaimer}
      equipmentBrand='John Deere'
      equipmentCondition='new'
      features={deereProduct.features}
      images={galleryImages}
      keyFeatures={keyFeatures}
      overview={overview}
      pricingLinks={pricingLinks}
      productType='john-deere'
      quoteFormSubmitButtonClass='submit-quote'
      quoteFormHiddenValues={[
        {
          name: 'Category Code',
          value: categoryCode,
        },
        {
          name: 'Subcategory Code',
          value: subcategoryCode,
        },
      ]}
      resourceLinks={resourceLinks}
      reviews={reviews}
      secondaryButton={secondaryButton}
      specs={deereProduct.specs}
      title={title}
      {...(expired
        ? {}
        : {
            price,
            displayPrice: payment,
            priceAppend: '/month',
            priceDisclaimer: `at ${
              financingInterestRate === 0 ? '0' : (financingInterestRate * 100).toFixed(2)
            }% for ${financingNumberPayments} months with no money down`,
            quoteFormHeader: 'Talk to Sales',
            buttonText: 'Talk to Sales',
          })}
    >
      <Helmet>
        <title>{meta.title}</title>
        <meta property='og:title' content={meta.title} />
        <meta property='og:url' content={location.href} />
        {meta.images.map((img, key) => (
          <meta property='og:image' content={img} key={key} />
        ))}
        <meta property='twitter:card' content='summary' />
        <meta name='twitter:site' content='@Hutson_Inc' />
      </Helmet>
    </Template>
  )
}

const SecondaryButtonA = styled(OutboundLink)`
  display: inline-block;
  margin: 0 0 15px;
  text-decoration: none;
`

const PlayButton = styled(UnstyledButton)`
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  bottom: 0;
  cursor: pointer;
  height: 60px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 100px;
  z-index: 1;

  :hover,
  :focus {
    background-color: rgba(0, 0, 0, 0.9);
  }

  :after {
    border-color: transparent transparent transparent rgba(255, 255, 255, 1);
    border-style: solid;
    border-width: 12.5px 0 12.5px 20px;
    content: '';
    display: block;
    left: 40px;
    margin: 0 auto;
    position: absolute;
    top: 16.5px;
  }
`

const trackApplyForFinancing = () => {
  gtag('event', gtagEvents.open_credit_application, {
    currency: 'USD',
    value: price || 0,
  })
  fbq('trackCustom', fbqEvents.Lead, {
    currency: 'USD',
    value: price || 0,
  })
}

const trackProductBrochure = () => {
  gtag('event', gtagEvents.view_brochure)
  fbq('trackCustom', fbqEvents.ViewBrochure)
}

const _renderImg = item => {
  return (
    <div className='image-gallery-gatsby-image'>
      <div className='image-gallery-gatsby-image-inner'>
        <GatsbyImage
          image={item.original}
          alt={item.originalAlt}
          objectFit='contain'
          style={{ width: '100%', height: '100%' }}
        />
      </div>
      {item.description && <span className='image-gallery-description'>{item.description}</span>}
    </div>
  )
}

const _renderImgThumb = item => {
  return (
    <div className='image-gallery-gatsby-image'>
      <div className='image-gallery-gatsby-image-inner'>
        <GatsbyImage
          image={item.thumbnail}
          alt={item.thumbnailAlt}
          objectFit='contain'
          style={{ width: '100%', height: '100%' }}
        />
      </div>
    </div>
  )
}

export const pageQuery = graphql`
  query gardenerPackageQuery($sku: String! = "3025elv") {
    sanitySiteMetadata {
      url
    }
    deereProduct(sku: { eq: $sku }) {
      attachmentsBrochure
      bodyHtml
      description
      features {
        title
        content
      }
      productBrochure
      reviews {
        reviews {
          author
          date
          text
          title
          pros
          cons
          usage
          recommended
          location
          rating {
            overall
            value
            quality
            ease
          }
        }
        overview {
          count
          recommendedPercentage
          rating {
            overall
            value
            quality
            ease
          }
          stars {
            stars1
            stars2
            stars3
            stars4
            stars5
          }
        }
      }
      sanityData {
        category {
          handle
          title
        }
        categoryCode
        images {
          asset {
            original: gatsbyImageData(width: 680)
            thumbnail: gatsbyImageData(layout: FIXED, width: 92)
          }
        }
        subcategory {
          handle
          ocaEquipmentType
          title
        }
        subcategoryCode
      }
      specs {
        name
        specs {
          description
          name
        }
      }
      videos {
        id
        thumbnail {
          childImageSharp {
            original: gatsbyImageData(width: 680)
            thumbnail: gatsbyImageData(layout: FIXED, width: 92)
          }
        }
      }
    }
    productImage: file(relativePath: { eq: "tractor-packages/gardener-tractor-package.jpg" }) {
      childImageSharp {
        original: gatsbyImageData(width: 680)
        thumbnail: gatsbyImageData(layout: FIXED, width: 92)
      }
    }
    loaderImage: file(relativePath: { eq: "product-features/300e-loader.jpg" }) {
      ...SharpImage900
    }
    palletForksImage: file(
      relativePath: { eq: "product-features/frontier-ap11f-pallet-forks.jpg" }
    ) {
      ...SharpImage900
    }
    tillerImage: file(relativePath: { eq: "product-features/frontier-rt11-series-tiller.jpg" }) {
      ...SharpImage900
    }
  }
`

export default GardenerTractorPackage
