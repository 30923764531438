/**
 * Calculate monthly payments similar to Excel's `pmt` function. Unlike the Excel version, this function takes the
 * interest rate as a yearly interest rate and will return a positive value.
 * @param {number} interestRate - interest rate per year
 * @param {number} numberPayments - number of periods in months
 * @param {number} presentValue - present value
 * @param {number} [futureValue=0] - future value
 * @param {0|1} [type=0] - when payments are due (`0` for end of period or `1` for beginning)
 */
const pmt = (interestRate, numberPayments, presentValue, futureValue = 0, type = 0) => {
  if (interestRate === 0) {
    return (presentValue + futureValue) / numberPayments
  }

  if (numberPayments !== 0) {
    const interestRatePerMonth = interestRate / 12
    const presentValueInterestFactor = Math.pow(1 + interestRatePerMonth, numberPayments)
    const payment =
      (interestRatePerMonth * presentValue * (presentValueInterestFactor + futureValue)) /
      (presentValueInterestFactor - 1)

    if (type === 1) {
      return payment / (1 + interestRatePerMonth)
    }

    return payment
  }

  return 0
}

module.exports = pmt
